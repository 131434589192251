<template>
  <div id="mian">
    <!-- 批量订单查询 -->
    <div>
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>分账商户</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="分账商户名称/编号"
              :no-data-text="divisionData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in divisionData"
                :key="item.allocMchId"
                :label="item.allocMchName + ' | ' + item.allocMchId"
                :value="item.allocMchId"
              >
              </el-option>
            </el-select>  
          </div>
          <div class="item">
            <i>所属商户</i>
            <el-select
              v-model="query.mchId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in mchDrop"
                :key="i"
                :label="v.mchName"
                :value="v.mchId"
              ></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>交易状态</i>
            <el-select
              v-model="query.orderStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="处理中" value="P3000"></el-option>
              <el-option label="交易成功" value="P1000"></el-option>
              <el-option label="交易失败" value="P2000"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>结算日期</i>
            <el-date-picker
              v-model="query.settleStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.settleEndDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
         <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
            <el-button
              v-auth="'TRADE:ALLOC:AUTO_SETTLES/EXPORT'"
              type="primary"
              class="addBt"
              @click="outputShow"
              >导出</el-button
            >         
         </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
        <div class="jiaoyi_box">
          <p><i>结算笔数（笔）</i></p>
          <p>
            <b>{{ tradeNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>结算金额（元）</i></p>
          <p>
            <b>{{ (settleAmount / 100) | formatMoney }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>分账商户手续费（元）</i></p>
          <p>
            <b>{{ (allocMchSettleFee / 100) | formatMoney }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>实际到账金额（元）</i></p>
          <p>
            <b>{{ (remitAmount / 100) | formatMoney }}</b>
          </p>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>结算日期</p>
            </td>
            <td>
              <p>分账商户名称</p>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>结算金额(元)</p>
              <p>分账商户手续费(元)</p>
            </td>
            <td>
              <p>实际到账金额(元)</p>
            </td>
            <td>
              <p>结算账户</p>
              <p>结算账户名</p>
            </td>
            <td>
              <p>交易状态</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.settleDate }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ (v.settleAmount / 100) | formatMoney }}</p>
              <p>{{ (v.allocMchSettleFee / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.remitAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.accountNo }}</p>
              <p>{{ v.accountName }}</p>
            </td>
            <td>
              <p>{{ orderStatus[v.orderStatus] }}</p>
            </td>
            <!-- <td>
              <p><a href="javascript:;" class="lianjie" @click="getLists(false)">重新生成</a></p>
            </td> -->
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <el-dialog title="导出交易记录" :visible.sync="outPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="outputForm.mchId" placeholder="选择商户">
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="outputForm.settleStartDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <el-select
            v-model="selectTimeType"
            @change="timeChange"
            placeholder="选择交易日期"
          >
            <el-option label="今天" value="1"></el-option>
            <el-option label="昨天" value="2"></el-option>
            <el-option label="近7天" value="7"></el-option>
            <el-option label="其它" value="-1"></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            :picker-options="endOption"
            v-model="outputForm.settleEndDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outPayShow = false">取 消</el-button>
        <el-button class="addBt1" @click="output">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  getAutosettles,
  exportAutosettlesorders,
} from "@/api/trading/subaccount.js";
import { merchantsDrop , DivisionDrop} from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
import { URL_API } from "@/utils/apiUrl.js";
export default {
  data() {
    let that = this;
    return {
      query: {
        settleStartDate: formatDate(new Date()- 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        settleEndDate: formatDate(new Date()- 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        pageNo: 1,
        pageSize: 10,
        keyword: "",
        mchId: "",
        orderStatus: "",
      },
      mchDrop: [],
      totalNum: 0,
      tradeNum:0,
      allocMchSettleFee: 0,
      settleAmount: 0,
      remitAmount: 0,
      tabData: [],
      crumbs: "批量查询",
      orderStatus: {
        P1000: "交易成功",
        P2000: "交易失败",
        P3000: "处理中",
        P3001: "处理中",
      },
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() < new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000  ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      outPayShow: false, //导出展示
      outputForm: {}, //导出信息
      selectTimeType:"",
      divisionData: [], //分账商户名称/编号可选项
      keywordData:{
        allocMchId:"",
        allocMchName:"",
      },
    };
  },
  computed: {
    ...mapState({
      automaticDetail: "trading_automatic",
    }),
  },
  created() {
    if (this.automaticDetail) {
      this.query = this.automaticDetail.query;
      this.keywordData = this.automaticDetail.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.allocMchId != ""){
      this.query.keyword = this.keywordData.allocMchName + ' | ' + this.keywordData.allocMchId;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path,this.getLists)
  },
  methods: {
    ...mapMutations({
      setAutomatic: "trading_setAutomatic",
    }),
    // 获取列表详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
        // this.query.keyword=this.query.keyword.trim()
      }
      if (!this.query.settleStartDate || !this.query.settleEndDate) {
        this.$message.error("结算日期段必选");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.query));
      if(this.keywordData != undefined && this.keywordData.allocMchId != ""){
        data.keyword = this.keywordData.allocMchId;
      }
      getAutosettles(data).then((res) => {
        if (res) {
          this.tabData = res.resultData.orders;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
          this.allocMchSettleFee = res.resultData.allocMchSettleFee;
          this.settleAmount = res.resultData.settleAmount;
          this.remitAmount = res.resultData.remitAmount;
          this.tradeNum = res.resultData.tradeNum;
        }
      });
    },
    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        settleStartDate: "",
        settleEndDate: "",
        mchId: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.settleStartDate = formatDate(
            +new Date(),
            "yyyy-MM-dd"
          );
          this.outputForm.settleEndDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.settleStartDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.settleEndDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.settleStartDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.settleEndDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.settleStartDate = "";
          this.outputForm.settleEndDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.settleStartDate || !this.outputForm.settleEndDate) {
        this.$message.error("导出时间段必选");
        return;
      }
      let data = this.outputForm;
      exportAutosettlesorders(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "自动结算查询.csv");
          document.body.appendChild(link);
          link.click();
        }
      });
      this.outPayShow = false;
    },
    // 获取商户下拉列表
    _searchAllMch(value) {
      this.divisionData = [];
      let params = {
        keyword: value,
      };
      DivisionDrop(params).then((res) => {
        if (res.resultStatus) {
          this.divisionData = res.resultData;
          console.log(this.divisionData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.divisionData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let allocMchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.divisionData.filter(e=>e.allocMchId === allocMchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    }, 
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },        
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      keywordData: this.keywordData,
    };
    this.setAutomatic(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime {
    width: 49.6%;
}
.addBt {
  float: right;
  width: 90px;
  height: 36px;
  border-color: #d7dae2;
  background-color: #fff;
  margin-left: 20px;
  padding: 0;
  color: #606266;
}

.addBt:hover {
  background-color: #fff;
  color: #48b8b6;
}
.addBt1 {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
